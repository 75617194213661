@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@media (min-width: 320px){
    .cidades {
        height: 160vh;
    }


    .title-cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.8rem;
    }


    .img-sp,
    .img-pb {
        height: 50vh;
    
    }

    .nortem {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
    }

    .cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
    }

}

@media (min-width: 576px){
    .cidades {
        height: 210vh;
    }


    .title-cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.8rem;
    }

    .nortem {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2rem;
    }

    .img-sp,
    .img-pb {
        height: 60vh;
    
    }

    .cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2.2rem;
    }

   
}

@media (min-width: 768px){
    .cidades {
        height: 220vh;
    }


    .title-cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.8rem;
    }

    .img-sp,
    .img-pb {
        height: 50vh;
    
    }

    .nortem {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2rem;
    }

    .cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2.2rem;
    }

}

@media (min-width: 992px){
    .cidades {
        height: 125vh;
    }


    .title-cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .nortem {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.3rem;
    }

    .img-sp,
    .img-pb {
        height: 60vh;
    
    }

    .cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2.2rem;
    }


}

@media (min-width: 1200px){
    .cidades {
        height: 125vh;
    }


    .title-cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3.5rem;
    }

    .img-sp,
    .img-pb {
        height: 60vh;
    
    }

    .nortem {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2.6rem;
    }

    
    
}

@media (min-width: 1400px){
    .cidades {
        height: 140vh;
    }


    .title-cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 4.5rem;
    }


    .img-sp,
    .img-pb {
        height: 70vh;
    
    }

    .nortem {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .cidades {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 3rem;
    }

   
}