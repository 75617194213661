@media (min-width: 320px){
    .row-grupo-nortem {
        height: 40vh;
    }
    }




@media (min-width: 576px){
    .row-grupo-nortem {
        height: 50vh;
        
    }
    }




@media (min-width: 768px){
    .row-grupo-nortem {
        height: 50vh;
        
    }
    }



@media (min-width: 992px){
    .row-grupo-nortem {
        height: 50vh;
        
    }
    }



@media (min-width: 1200px){
    .row-grupo-nortem {
        height: 50vh;
        
    }
    }

@media (min-width: 1400px){
.row-grupo-nortem {
    height: 50vh;
    
}
}