@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@media (min-width: 365px){
    .row-info {
        height: 180vh;
    }

    .title-info {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        text-align: center;
    }

    .subtitle-info {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: center;
    }

    .btn-info {
        border: 1px solid black;
        background-color: white;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        height:6vh;
        width: 100%;
    }

    .col-info {
        height: 20vh;
    }

    .card-info {
        border: 1.5px solid black;
        height: 80vh;
    }

    .forms {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1.1rem;
    }

    .btn-forms {
        border: none;
        color: white;
        background-color: black;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        height: 5vh;
    }
}


@media (min-width: 576px){
    .row-info {
        height: 180vh;
    }

    .title-info {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        text-align: center;
    }

    .subtitle-info {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: center;
    }

    .btn-info {
        border: 1px solid black;
        background-color: white;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        height:6vh;
        width: 100%;
        
    }

    .col-info {
        height: 20vh;
    }

    .card-info {
        border: 1.5px solid black;
        height: 80vh;
    }

    .forms {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1.1rem;
    }

    .btn-forms {
        border: none;
        color: white;
        background-color: black;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        height: 5vh;
    }
}


@media (min-width: 768px){
    .row-info {
        height: 100vh;
    }

    .title-info {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        text-align: start;
    }

    .subtitle-info {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: start;
    }

    .btn-info {
        border: 1px solid black;
        background-color: white;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        height:6vh;
        width: 100%;
    }

    .col-info {
        height: 65vh;
    }

    .card-info {
        border: 1.5px solid black;
        height: 80vh;
    }

    .forms {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1.1rem;
    }

    .btn-forms {
        border: none;
        color: white;
        background-color: black;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        height: 5vh;
    }
}

@media (min-width: 992px){
    .row-info {
        height: 100vh;
    }

    .title-info {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 2rem;
        text-align: start;
        margin-left: 3rem;
    }

    .subtitle-info {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-align: start;
        margin-left: 3rem;
    }

    .btn-info {
        border: 1px solid black;
        background-color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 3rem;
        font-size: 1rem;
        font-weight: 600;
        height:6vh;
        width: 70%;
    }

    .col-info {
        height: 65vh;
    }

    .card-info {
        border: 1.5px solid black;
        height: 80vh;
    }

    .forms {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1.1rem;
    }

    .btn-forms {
        border: none;
        color: white;
        background-color: black;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        height: 5vh;
    }
}


@media (min-width: 1200px){
    .row-info {
        height: 100vh;
    }

    .title-info {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 2rem;
        text-align: start;
        margin-left: 3rem;
    }

    .subtitle-info {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-align: start;
        margin-left: 3rem;
    }

    .btn-info {
        border: 1px solid black;
        background-color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 3rem;
        font-size: 1.1rem;
        font-weight: 600;
        height:6vh;
        width: 53.5%;
   
    }

    .col-info {
        height: 65vh;
    }

    .card-info {
        border: 1.5px solid black;
        height: 80vh;
    }

    .forms {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1.1rem;
    }

    .btn-forms {
        border: none;
        color: white;
        background-color: black;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        height: 5vh;
    }
}


@media (min-width: 1440px){
    .row-info {
        height: 100vh;
    }

    .title-info {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 2rem;
        text-align: start;
        margin-left: 3rem;
    }

    .subtitle-info {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        text-align: start;
        margin-left: 3rem;
    }

    .btn-info {
        border: 1px solid black;
        background-color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 3rem;
        font-size: 1.3rem;
        font-weight: 600;
        height:6vh;
        width: 40%;
    }

    .col-info {
        height: 65vh;
    }

    .card-info {
        border: 1.5px solid black;
        height: 80vh;
    }

    .forms {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 1.1rem;
    }

    .btn-forms {
        border: none;
        color: white;
        background-color: black;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        height: 5vh;
    }
}