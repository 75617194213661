/* InfiniteSlider.css */

.slider {
    width: 100%;
    height: var(--height);
    height: 50vh;
    overflow: hidden;
    position: relative;
    mask-image: linear-gradient(to right, transparent, #000 10%, #000 90%, transparent);
}

.slider .list {
    display: flex;
    width: calc(var(--width) * 3); /* Duplique a largura para dar continuidade ao loop */
    animation: autoRun 40s linear infinite; /* Ajuste a duração conforme necessário */
}

.slider .list .item {
    display: flex;
    gap: 10px;
}

@keyframes autoRun {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-1 * var(--width)));
    }
}

.slider:hover .list {
    animation-play-state: paused; /* Pausa a animação ao passar o mouse */
}

@media (min-width: 320px) and (max-width: 768px) {
    .slider .list {
        display: flex;
        width: calc(var(--width) * 1.3); /* Duplique a largura para dar continuidade ao loop */
        animation: autoRun 40s linear infinite; /* Ajuste a duração conforme necessário */
    }

    .slider {
        height: 65vh;
    }
    
}