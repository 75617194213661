@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@media (min-width: 320px){
    .row-history {
        height: 60vh;
    }
    
    .row-history-cards {
        background:black;
        height: 230vh;
    
    }
    
    .title-history {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.5rem;
    }
    
    .subtitle-history {
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        color: rgba(164, 161, 161, 1);
        font-weight: 800;
    }
    
    .history {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model1.png);
        background-size: cover;
        background-position: center top 26%;
        border-radius: 15px;
    }

    .history::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .history2 {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model2.png);
        background-size: cover;
        background-position: center top 24%;
        border-radius: 15px;
    }

    .history2::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history2:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    

    
    
    .history3 {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model3.png);
        background-size: cover;
        background-position: center top 30%;
        border-radius: 15px;
    }

    .history3::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history3:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .ceo {
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: white;   
    }
    
    .agency {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-family: 400;
        color: white;
    }
    
    .text {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
    }

    .btn-history{
        position: relative;
        top: -15rem;
        font-family: 'Anek Latin',sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        border: none;
        border-radius: 10px;
        height: 6vh;
        width: 100%;
    }
    
    
}



@media (min-width: 576px){
    .row-history {
        height: 100vh;
    }
    
    .row-history-cards {
        background: black;
        height: 230vh;
    
    }
    
    .title-history {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.7rem;
    }
    
    .subtitle-history {
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        color: rgba(164, 161, 161, 1);
        font-weight: 800;
    }
    
    .history {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model1.png);
        background-size: cover;
        background-position: center top 26%;
        border-radius: 15px;
    }

    .history::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .history2 {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model2.png);
        background-size: cover;
        background-position: center top 24%;
        border-radius: 15px;
        overflow: hidden;
    }

    .history2::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history2:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    

    
    
    .history3 {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model3.png);
        background-size: cover;
        background-position: center top 30%;
        border-radius: 15px;
    }

    .history3::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history3:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .ceo {
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: white;   
    }
    
    .agency {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-family: 400;
        color: white;
    }
    
    .text {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
    }

    .btn-history{
        position: relative;
        top: -14rem;
        font-family: 'Anek Latin',sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        border: none;
        border-radius: 10px;
        height: 6vh;
        width: 70%;
    }
    
    
}


@media (min-width: 768px){
    .row-history {
        height: 100vh;
    }
    
    .row-history-cards {
        background: black;
        height: 140vh;
    
    }
    
    .title-history {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 2rem;
    }
    
    .subtitle-history {
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        color: rgba(164, 161, 161, 1);
        font-weight: 800;
    }
    
    .history {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model1.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    .history::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .history2 {
        position: relative;
        margin-top: -20rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model2.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
    }

    .history2::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history2:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .history3 {
        position: relative;
        margin-top: -4rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model3.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
    }

    .history3::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history3:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .ceo {
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: white;   
    }
    
    .agency {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-family: 400;
        color: white;
    }
    
    .text {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
    }

    .btn-history{
        position: relative;
        top: -8rem;
        font-family: 'Anek Latin',sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        border: none;
        border-radius: 10px;
        height: 6vh;
        width: 70%;
    }
    
    
}


@media (min-width: 992px){
    .row-history {
        height: 100vh;
    }
    
    .row-history-cards {
        background: black;
        height: 100vh;
    
    }
    
    .title-history {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 2rem;
    }
    
    .subtitle-history {
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        color: rgba(164, 161, 161, 1);
        font-weight: 800;
    }
    
    .history {
        position: relative;
        margin-top: -18rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model1.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    
    .history::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    


    
    
    .history2 {
        position: relative;
        margin-top: -18rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model2.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
    }

    .history2::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 50%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history2:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .history3 {
        position: relative;
        margin-top: -18rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model3.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
    }

    
    .history3::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 50%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history3:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .ceo {
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: white;   
    }
    
    .agency {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-family: 400;
        color: white;
    }
    
    .text {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
    }

    .btn-history{
        position: relative;
        top: -12rem;
        font-family: 'Anek Latin',sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        border: none;
        border-radius: 10px;
        height: 6vh;
        width: 50%;
    }
    
    
}




@media (min-width: 1200px){
    .row-history {
        height: 100vh;
    }
    
    .row-history-cards {
        background: black;
        height: 70vh;
    
    }
    
    .title-history {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 2rem;
    }
    
    .subtitle-history {
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        color: rgba(164, 161, 161, 1);
        font-weight: 800;
    }
    
    .history {
        position: relative;
        margin-top: -18rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model1.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    .history::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .history2 {
        position: relative;
        margin-top: -18rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model2.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    .history2::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history2:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .history3 {
        position: relative;
        margin-top: -18rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model3.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    .history3::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 60%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history3:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    
    .ceo {
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: white;   
    }
    
    .agency {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-family: 400;
        color: white;
    }
    
    .text {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
    }

    .btn-history{
        position: relative;
        top: -6rem;
        font-family: 'Anek Latin',sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        border: none;
        border-radius: 10px;
        height: 6vh;
        width: 30%;
    }
    
    
}




@media (min-width: 1440px){
    .row-history {
        height: 45vh;
    }
    
    .row-history-cards {
        background: black;
        height: 80vh;
    
    }
    
    .title-history {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 2rem;
    }
    
    .subtitle-history {
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        color: rgba(164, 161, 161, 1);
        font-weight: 800;
    }
    
    .history {
        position: relative;
        margin-top: -21rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model1.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;   
    }

    .history::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 80%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history:after {
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
    
    
    .history2 {
        position: relative;
        margin-top: -21rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model2.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    .history2::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 80%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history2:after {
        background: linear-gradient(to top, rgb(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .history3 {
        position: relative;
        margin-top: -21rem;
        height: 50vh;
        background-image: url(../../assets/img/History-Images/Model3.png);
        background-size: cover;
        background-position: center;
        border-radius: 15px;
        overflow: hidden;
    }

    .history3::after {
        content: '';
        position: absolute;
        bottom: -10%;
        left: 0;
        width: 100%;
        height: 80%; 
        background: linear-gradient(to top, rgba(0, 0, 0, 1));
        pointer-events: none; 
        z-index: -1;
    }

    .history3:after {
        background: linear-gradient(to top, rgb(0, 0, 0, 1), transparent);
    }
    
    
    
    
    .ceo {
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        color: white;   
    }
    
    .agency {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-family: 400;
        color: white;
    }
    
    .text {
        font-family: 'Inter', sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        color: white;
    }

    .btn-history{
        position: relative;
        top: -8rem;
        font-family: 'Anek Latin',sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
        border: none;
        border-radius: 10px;
        height: 6vh;
        width: 20%;
    }

    
}


