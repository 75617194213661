@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@media (min-width: 320px) {

    .row-trust {
        height: 180vh;
    }
    
    .title-trust {
        font-size: 1.4rem;
    }

    .subtitle-trust {
        font-size: 1.1rem;
    }

    
}

@media (min-width: 456px) {

    .row-trust {
        height: 100vh;
    }
    

    .title-trust {
        font-size: 1.8rem;
    }

    .subtitle-trust {
        font-size: 1.1rem;
    }

    
}

@media (min-width: 576px) {
    
    .row-trust {
        height: 100vh;
    }

    .title-trust {
        font-size: 2rem;
    }

    .subtitle-trust {
        font-size: 1.1rem;
    }
}



@media (min-width: 1440px) {
    .row-trust {
        height: 70vh;
    }

    .title-trust {
        font-family:"Anek Latin", sans-serif;
        font-size: 2.5rem;
    }

    .subtitle-trust {
        font-family: 'Anek Latin', sans-serif;
    }

    
    
}