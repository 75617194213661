@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@media (min-width: 365px) {
    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 60px; /* Tamanho da fonte do número */
        font-weight: 700;
        margin-top: 1rem;
    }

    .title-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        width:80%;
    }

    .subtitle-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .btn-modal {
        border: none;
        background-color: black;
        color: white;
        font-family: 'Anek Latin',sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        width: 100%;
        height: 5vh;
    }

   

}





@media (min-width: 576px) {
    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 60px; /* Tamanho da fonte do número */
        font-weight: 700;
        margin-top: 1rem;
    }

    .title-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        width:80%;
    }

    .subtitle-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .btn-modal {
        border: none;
        background-color: black;
        color: white;
        font-family: 'Anek Latin',sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        width: 100%;
        height: 5vh;
    }

   

}



@media (min-width: 768px) {
    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 60px; /* Tamanho da fonte do número */
        font-weight: 700;
        margin-top: 1rem;
    }

    .title-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.2rem;
        width:80%;
    }

    .subtitle-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .btn-modal {
        border: none;
        background-color: black;
        color: white;
        font-family: 'Anek Latin',sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        width: 100%;
        height: 5vh;
    }

   

}




@media (min-width: 992px) {
    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 1rem;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 70px; /* Tamanho da fonte do número */
        font-weight: 700;
    }

    .title-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 0.8rem;
        width: 80%;
    }

    .subtitle-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.7rem;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .btn-modal {
        border: none;
        background-color: black;
        color: white;
        font-family: 'Anek Latin',sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        width: 100%;
        height: 5vh;
    }

}




@media (min-width: 1200px) {
    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3.4rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 1.2rem;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 70px; /* Tamanho da fonte do número */
        font-weight: 700;
    }

    .title-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1rem;
        width: 80%;
    }

    .subtitle-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .btn-modal {
        border: none;
        background-color: black;
        color: white;
        font-family: 'Anek Latin',sans-serif;
        font-size: 2rem;
        font-weight: 700;
        width: 100%;
        height: 5vh;
    }

}


@media (min-width: 1440px) {
    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 70px; /* Tamanho da fonte do número */
        font-weight: 700;
    }

    .title-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1rem;
        width: 80%;
    }

    .subtitle-circle {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .btn-modal {
        border: none;
        background-color: black;
        color: white;
        font-family: 'Anek Latin',sans-serif;
        font-size: 2rem;
        font-weight: 700;
        width: 100%;
        height: 5vh;
    }

}