@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@media (min-width: 320px){
    .row-diferenciais{
        height: 100vh;
    }

    .row-cards-dife{
        height: 170vh;
    }

    .title-diferenciais {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 800;
        text-align: center;
    
    }

    .card3-dife,
    .card2-dife,
    .card1-dife {
        border-radius: 15px;
        background-color: black
    }
    

    .icon1-services,
    .icon2-services,
    .icon3-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }


    .title {
        position: relative;
        font-family: 'Inter', sans-serif;
    }

    .title-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 2rem;
    }

    .subtitle-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    
    .btn-card-services {
        font-family: "Anek Latin", sans-serif;
        font-weight: 700;
        height: 5vh;
        font-size: 1.5rem;
        border: none;
        border: 8px;
    }


    .line-card1{
        position: relative;
        top: 30px;
    }

    .btn-outside {
        font-family: 'Anek Latin', sans-serif;
        background-color: white;
        font-weight: 700;
        border: 1px solid black;
        border-radius: 12px;
        width: 80%;
        height: 6vh;
    }


   
}

@media (min-width: 576px){
    .row-diferenciais{
        height: 100vh;
    }

    .row-cards-dife {
        height: 170vh;
    }

    .title-diferenciais {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 800;
        text-align: start;
    }

    .title {
        font-family: 'Inter', sans-serif;
    }

    .title-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 2rem;
    }

    .card3-dife,
    .card2-dife,
    .card1-dife {
        border-radius: 15px;
        background-color: black
    }
    

    .icon1-services,
    .icon2-services,
    .icon3-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }

    .subtitle-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    .btn-card-services {
        font-family: "Anek Latin", sans-serif;
        font-weight: 700;
        height: 5vh;
        font-size: 1.5rem;
        border: none;
        border: 8px;
    }

    .line-card1{
        position: relative;
        top: 30px;
    }

     
    .btn-outside {
        font-family: 'Anek Latin', sans-serif;
        background-color: white;
        font-weight: 700;
        font-size: 1rem;
        border: 1px solid black;
        border-radius: 12px;
        width: 80%;
        height: 6vh;
    }

   
}


@media (min-width: 768px){
    .row-diferenciais{
        height: 100vh;
    }

    .row-cards {
        height: 150vh;
    }

    .title-diferenciais {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 800;
        text-align: start;
    }

    .card3-dife,
    .card2-dife,
    .card1-dife {
        border-radius: 15px;
        background-color: black
    }
    

    .icon1-services,
    .icon2-services,
    .icon3-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }

    .title {
        position: relative;
        font-family: 'Inter', sans-serif;
        top: 50px;
    }
    
    .title-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 2rem;
    }

    .subtitle-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    .btn-card-services {
        font-family: "Anek Latin", sans-serif;
        font-weight: 700;
        height: 5vh;
        font-size: 1.5rem;
        border: none;
        border: 8px;
    }

    .line-card1{
        position: relative;
        top: 30px;
    }

    
    .btn-outside {
        font-family: 'Anek Latin', sans-serif;
        background-color: white;
        font-weight: 700;
        font-size: 1.2rem;
        border: 1px solid black;
        border-radius: 12px;
        width: 80%;
        height: 6vh;
    }
   

   
}


@media (min-width: 992px){
    .row-diferenciais{
        height: 100vh;
    }

    .row-cards {
        height: 150vh;
    }

    .title-diferenciais {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 800;
        text-align: start;
    }

    .card3-dife,
    .card2-dife,
    .card1-dife {
        border-radius: 15px;
        background-color: black
    }
    

    .icon1-services,
    .icon2-services,
    .icon3-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }

    .title {
        position: relative;
        font-family: 'Inter', sans-serif;
        top: 50px;
    }
    
    .title-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 2rem;
    }

    .subtitle-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    .btn-card-services {
        font-family: "Anek Latin", sans-serif;
        font-weight: 700;
        height: 5vh;
        font-size: 1.5rem;
        border: none;
        border: 8px;
    }

    .line-card1{
        position: relative;
        top: 30px;
    }

    .btn-outside {
        font-family: 'Anek Latin', sans-serif;
        background-color: white;
        font-weight: 700;
        font-size: 1.2rem;
        border: 1px solid black;
        border-radius: 12px;
        width: 50%;
        height: 6vh;
    }
   
}


@media (min-width: 1200px){
    .row-diferenciais{
        height: 100vh;
    }

    .row-cards {
        height: 150vh;
    }

    .title-diferenciais {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 800;
        text-align: start;
    }

    .card3-dife,
    .card2-dife,
    .card1-dife {
        border-radius: 15px;
        background-color: black
    }
    

    .icon1-services,
    .icon2-services,
    .icon3-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }

    .title {
        position: relative;
        font-family: 'Inter', sans-serif;
        top: 50px;
    }
    
    .title-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 2rem;
    }

    .subtitle-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    .btn-card-services {
        font-family: "Anek Latin", sans-serif;
        font-weight: 700;
        height: 5vh;
        font-size: 1.5rem;
        border: none;
        border: 8px;
    }
    .line-card1{
        position: relative;
        top: 30px;
    }

    .btn-outside {
        font-family: 'Anek Latin', sans-serif;
        background-color: white;
        font-weight: 700;
        font-size: 1.5rem;
        border: 1px solid black;
        border-radius: 12px;
        width: 50%;
        height: 6vh;
    }
   
}






@media (min-width: 1400px){

    .row-diferenciais{
        height: 80vh;
    }

    .row-cards-dife {
        height: 100vh;
    }

    .title-diferenciais {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 800;
        text-align: start;
    }

    .card3-dife,
    .card2-dife,
    .card1-dife {
        border-radius: 15px;
        background-color: black
    }
    

    .icon1-services,
    .icon2-services,
    .icon3-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
    }


    .title {
        position: relative;
        font-family: 'Inter', sans-serif;
    }

    .title-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 2rem;
    }

    .subtitle-card1 {
        font-family: "Anek Latin", sans-serif;
        font-size: 1rem;
        font-weight: 300;
    }

    .btn-card-services {
        font-family: "Anek Latin", sans-serif;
        font-weight: 700;
        height: 5vh;
        font-size: 1.5rem;
        border: none;
        border: 8px;
    }

    .line-card1{
        position: relative;
        top: 30px;
    }

    .btn-outside {
        font-family: 'Anek Latin', sans-serif;
        background-color: white;
        font-weight: 700;
        font-size: 1.5rem;
        border: 1px solid black;
        border-radius: 12px;
        width: 50%;
        height: 6vh;
    }

   
   
}