@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.video-container {
    position: relative;
    width: 80%;
    height: 35vh; /* O vídeo ocupará a altura total da viewport */
    overflow: hidden;
  }
  
  .video-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que o vídeo cubra a área toda */
    transform: translate(-50%, -50%);
    z-index: 1; /* Envia o vídeo para o fundo */
  }