@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.header {
    height: 90vh;
    background-image: url('../../assets/img/Header-Background/Background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
