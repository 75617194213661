@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@media (min-width: 365px) {
    .row-header-solucoes {
        height: 50vh;
    }

    .title-header-solucoes {
        font-size: 1.8rem;
        font-weight: 700;
        font-family: 'Anek Latin', sans-serif;
        width: 100%;
    }

    .subtitle-header-solucoes {
        font-family: 'Anek Latin', sans-serif;
        font-size: 1.3rem;
        font-weight: 700;
    }
}


@media (min-width: 576px) {
    .row-header-solucoes {
        height: 70vh;
    }

    .title-header-solucoes {
        font-size: 2.8rem;
        font-weight: 700;
        font-family: 'Anek Latin', sans-serif;
        width: 100%;
    }

    .subtitle-header-solucoes {
        font-family: 'Anek Latin', sans-serif;
        font-size: 1.4rem;
        font-weight: 700;
    }
}


@media (min-width: 768px) {
    .row-header-solucoes {
        height: 70vh;
    }

    .title-header-solucoes {
        font-size: 2.8rem;
        font-weight: 700;
        font-family: 'Anek Latin', sans-serif;
        width: 100%;
    }

    .subtitle-header-solucoes {
        font-family: 'Anek Latin', sans-serif;
        font-size: 1.6rem;
        font-weight: 700;
    }
}


@media (min-width: 992px) {
    .row-header-solucoes {
        height: 70vh;
    }

    .title-header-solucoes {
        font-size: 3rem;
        font-weight: 700;
        font-family: 'Anek Latin', sans-serif;
        width: 100%;
    }

    .subtitle-header-solucoes {
        font-family: 'Anek Latin', sans-serif;
        font-size: 2rem;
        font-weight: 700;
    }
}



@media (min-width: 1200px) {
    .row-header-solucoes {
        height: 70vh;
    }

    .title-header-solucoes {
        font-size: 3.8rem;
        font-weight: 700;
        font-family: 'Anek Latin', sans-serif;
        width: 100%;
    }

    .subtitle-header-solucoes {
        font-family: 'Anek Latin', sans-serif;
        font-size: 2.5rem;
        font-weight: 700;
    }
}


@media (min-width: 1440px) {
    .row-header-solucoes {
        height: 70vh;
    }

    .title-header-solucoes {
        font-size: 3.8rem;
        font-weight: 700;
        font-family: 'Anek Latin', sans-serif;
        width: 100%;
    }

    .subtitle-header-solucoes {
        font-family: 'Anek Latin', sans-serif;
        font-size: 2.5rem;
        font-weight: 700;
    }
}