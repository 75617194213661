@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@media (min-width: 320px) {

  .bck {
    background-color: black;
  }
  
  .text {
    font-family: 'Inter', sans-serif;
  }
  
  .btn-footer {
    border: none;
    width: 100%;
    height: 4vh;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }

  .logo-footer {
    width: 60px;
    
  }
  
  .line {
    margin-left: -1.5rem;
    width: 100%;
  }

  .coluna1 {
    margin-top: 2rem;
  }
  
  .coluna2 {
    margin-top: 5rem;
  }

  .coluna3 {
    margin-top: 5rem;
  }
  

}





@media (min-width: 576px) {

  .bck {
    background-color: black;
  }
  
  .text {
    font-family: 'Inter', sans-serif;
  }
  
  .btn-footer {
    border: none;
    width: 100%;
    height: 4vh;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
  
  .line {
    margin-left: -1.5rem;
  }
  
  .coluna2 {
    margin-top: 3rem;
  }

  .coluna3 {
    margin-top: 3rem;
  }
  

}



@media (min-width: 768px) {

  .bck {
    background-color: black;
  }
  
  .text {
    font-family: 'Inter', sans-serif;
  }
  
  .btn-footer {
    border: none;
    width: 100%;
    height: 4vh;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
  
  .line {
    margin-left: -1.5rem;
    width: 60%;
  }
  
  .coluna2 {
    margin-top: 3rem;
  }

  .coluna3 {
    margin-top: 3rem;
  }
  

}


@media (min-width: 992px) {
  .bck {
    background-color: black;
  }
  
  .text {
    font-family: 'Inter', sans-serif;
  }
  
  .btn-footer {
    border: none;
    width: 100%;
    height: 4vh;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
  
  .line {
    margin-left: -1.5rem;
    width: 50%;
  }

  .coluna2,
  .coluna3 {
    margin-top: 0;
  }


  
}


@media (min-width: 1200px) {
  .bck {
    background-color: black;
  }
  
  .text {
    font-family: 'Inter', sans-serif;
  }
  
  .btn-footer {
    border: none;
    width: 100%;
    height: 4vh;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
  
  .line {
    margin-left: -1.5rem;
    width: 50%;
  }

  .coluna2,
  .coluna3,
  .coluna1 {
    margin-top: 0;
  }


  
}


@media (min-width: 1400px) {
  .bck {
    background-color: black;
    
  }
  
  .text {
    font-family: 'Inter', sans-serif;
  }
  
  .btn-footer {
    border: none;
    width: 100%;
    height: 4vh;
    text-align: center;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
  }
  
  .line {
    margin-left: -1.5rem;
    width: 40%;
  }

  .coluna2,
  .coluna3,
  .coluna1 {
    margin-top: 0;
  }


  
}