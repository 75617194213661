@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');



@media (min-width: 320px){
    .row-group {
        height: 160vh;
}

    .img-group {
        height: 70vh;
    }

    .col-sm-6{
        height: 60vh;
    }

    .text-group {
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;
    
    }
    
  
}



@media (min-width: 576px){
    .row-group {
        height: 150vh;
}

    .img-group {
        height: 80vh;
    }

    .col-sm-6{
        height: 70vh;
    }

    .text-group {
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;
    }
    
  
}




@media (min-width: 768px){
    .row-group {
        height: 170vh;
        
}

    .img-group {
        height: 80vh;
        
    }

    .cont-text-group{
        height: 55vh;
    }

    .text-group {
        font-size: 2rem;
        font-weight: 400;
        text-align: center;
    }

 
    
  
}



@media (min-width: 992px){
    .row-group {
        height: 100vh;
}

    .img-group {
        height: 80vh;
    }

    .cont-text-group{
        height: 60vh;
    }

    .text-group {
        font-size: 1.3rem;
        font-weight: 400;
        text-align: start;
    }
    
  
}



@media (min-width: 1200px){
    .row-group {
        height: 100vh;
}

    .img-group {
        height: 80vh;
    }

    .cont-text-group{
        height: 70vh;
    }

    .text-group {
        font-size: 1.6rem;
        font-weight: 400;
        text-align: start;
    }
    
  
}



@media (min-width: 1440px){
    .row-group {
        height: 120vh;
}

    .img-group {
        height: 80vh;
    }

    .cont-text-group{
        height: 60vh;
    }

    .text-group {
        font-size: 1.8rem;
        font-weight: 400;
        text-align: start;
    }
    
  
}