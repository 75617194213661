@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@media (min-width: 320px) {
    .row-services {
        height: 410vh;
    }

    .title-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.2rem;
    }

    .subtitle-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .btn-card1-services {
        border: none;
        background-color: white;
        margin-left: 5rem;
        margin-right: 3rem;
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        height: 4.3vh;
    }

    .card-services {
        border-radius: 12px;
        margin-top: 2rem;
        background: rgba(26, 26, 26, 1);
    }

    .icon1-services {
        width: 40px;
    }

    .line-services {
        margin-left: 15rem;
    }

    .title-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
    }

    .subtitle-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
    }

    .x {
        height: 70vh;
    }
}



@media (min-width: 576px) {
    .row-services {
        height: 380vh;
    }

    .title-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 1.7rem;
    }

    .btn-card1-services {
        border: none;
        background-color: white;
        margin-left: 8rem;
        margin-right: 3rem;
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        height: 4.3vh;
    }

    .card-services {
        border-radius: 12px;
        margin-top: 2rem;
        background: rgba(26, 26, 26, 1);
    }

    .icon1-services {
        width: 40px;
    }

    .line-services {
        margin-left: 15rem;
    }

    .title-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
    }

    .subtitle-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
    }

    .x {
        height: 50vh;
    }
}


@media (min-width: 768px) {
    .row-services {
        height: 220vh;
    }

    .title-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .subtitle-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2rem;
    }

    .btn-card1-services {
        border: none;
        background-color: white;
        margin-left: 5rem;
        margin-right: 3rem;
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        height: 4.3vh;
    }

    .card-services {
        border-radius: 12px;
        margin-top: 0;
        background: rgba(26, 26, 26, 1);
    }

    .icon1-services {
        width: 40px;
    }

    .line-services {
        margin-left: 8rem;
    }

    .title-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
    }

    .subtitle-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
    }

    .x {
        height: 50vh;
    }
}





@media (min-width: 992px) {
    .row-services {
        height: 220vh;
    }

    .title-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .subtitle-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2rem;
    }

    .btn-card1-services {
        border: none;
        background-color: white;
        margin-left: 10rem;
        margin-right: 3rem;
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        height: 4.3vh;
    }

    .card-services {
        border-radius: 12px;
        margin-top: 0;
        background: rgba(26, 26, 26, 1);
    }

    .icon1-services {
        width: 40px;
    }

    .line-services {
        margin-left: 15rem;
    }

    .title-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
    }

    .subtitle-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
    }

    .x {
        height: 50vh;
    }
}



@media (min-width: 1200px) {
    .row-services {
        height: 220vh;
    }

    .title-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .subtitle-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2rem;
    }

    .btn-card1-services {
        border: none;
        background-color: white;
        margin-left: 16rem;
        margin-right: 3rem;
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        height: 4.3vh;
    }

    .card-services {
        border-radius: 12px;
        margin-top: 0;
        background: rgba(26, 26, 26, 1);
    }
    .icon1-services {
        width: 40px;
    }

    .line-services {
        margin-left: 22rem;
    }

    .title-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
    }

    .subtitle-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
    }

    .x {
        height: 40vh;
    }
}


@media (min-width: 1440px) {
    .row-services {
        height: 260vh;
    }

    .title-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .subtitle-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
        font-size: 2rem;
    }

    .btn-card1-services {
        border: none;
        background-color: white;
        margin-left: 16rem;
        margin-right: 3rem;
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        height: 4.3vh;
    }

    .card-services {
        border-radius: 12px;
        margin-top: 0;
        background: rgba(26, 26, 26, 1);
    }

    .icon1-services {
        width: 40px;
    }

    .title-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 3rem;
    }

    .title-modal2 {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .subtitle-modal {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;

    }

    .circle {
        width: 100px;
        height: 100px;
        font-family: 'Anek Latin',sans-serif;
        background-color: black; /* Cor da bola */
        border-radius: 50%; /* Faz o div ficar redondo */
        display: flex; /* Usa flexbox para centralizar o conteúdo */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        color: white; /* Cor do número */
        font-size: 70px; /* Tamanho da fonte do número */
        font-weight: 700;
    }

    .icon1 {
        background-color: black;
        color: white;
        height: 10vh;
        width: 40%;
        border-radius: 50%;
    }

    .um {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 600;
    }

    .line-services {
        margin-left: 22rem;
    }

    .line-modal {
        margin-left: 1.47rem;
    }

    .title-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 700;
    }

    .subtitle-card1-services {
        font-family: 'Anek Latin', sans-serif;
        font-weight: 400;
    }

    .x {
        height: 60vh;
    }
    
}